<template>
    <div class="wrap">
        <div class="manageBox">
            <div class="top">
                <el-form :inline="true" :model="queryParams" label-width="90px" class="demo-form-inline">
                    <el-form-item label="年龄">
                        <el-select class="w200px" v-model="queryParams.age" clearable placeholder="请选择年龄">
                            <el-option v-for="item in stateOpt" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="身份类别">
                        <el-select class="w200px" v-model="queryParams.type" clearable placeholder="请选择身份类别">
                            <el-option v-for="(item, index) in examineGradeOpt" :key="index" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="search" @click="searchList">搜索</el-button>
                        <el-button type="primary" class="search" plain @click="handlerResetList">重置</el-button>
                    </el-form-item>
                    <el-button type="primary" style="float: right" class="search" @click="newdd">新增</el-button>
                </el-form>
            </div>
            <div class="content">
                <el-table v-loading="loading" :data="tableData" border style="width: 100%" :header-cell-style="headerCellStyle">
                    <template slot="empty">
                        <IsEmpty />
                    </template>
                    <el-table-column type="index" label="序号" :index="count" align="center" width="80" />
                    <el-table-column prop="levelName" align="center" label="等级名称" show-overflow-tooltip />
                    <el-table-column prop="age" align="center" label="年龄(岁)" width="160" />
                    <el-table-column prop="type" align="center" label="身份类别">
                        <template v-slot="scope">
                            <span>{{ ['普通老人', '低保老人', '失能老年人', '低保失能老年人'][scope.row.type * 1] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="subsidyStandard" align="center" label="月标准(元)" />
                    <el-table-column prop="userName" align="center" label="创建人" />
                    <el-table-column prop="createTime" align="center" label="创建时间" />
                    <el-table-column label="操作" align="center" fixed="right" min-width="120">
                        <template slot-scope="scope">
                            <el-button type="text" @click="toEdit(scope.row)">编辑</el-button>
                            <el-button type="text" @click="toView(scope.row)">查看</el-button>
                            <el-button type="text" style="color: #f57473" @click="toDel(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    v-if="total > 0"
                    style="margin: 20px 0"
                    background
                    layout="total, sizes, prev, pager, next"
                    :total="total"
                    :page-size="queryParams.pageSize"
                    :current-page="queryParams.pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>
        <addOrEditLevel v-if="dialogVisible" :rowItem="rowItem" @updateList="getList" :dialogVisible.sync="dialogVisible"></addOrEditLevel>
        <viewLevel v-if="viewVisible" :rowItem="rowItem" :viewVisible.sync="viewVisible"></viewLevel>
    </div>
</template>

<script>
import addOrEditLevel from './components/addOrEditLevel'
import viewLevel from './components/viewLevel'
import { parseTime } from '@/utils/index'
import { getSubsidyLevelList, deleteSubsidyLevel } from '@/api/highCollarSubsidy'
export default {
    components: {
        addOrEditLevel,
        viewLevel
    },
    data() {
        return {
            viewVisible: false,
            dialogVisible: false,
            total: 0,
            loading: false,
            options: [],
            tableData: [],
            time: '',
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                age: '',
                type: ''
            },
            examineGradeOpt: [
                { label: '普通老年人', value: 0 },
                { label: '低保老年人', value: 1 },
                { label: '失能老年人', value: 2 },
                { label: '低保失能老年人', value: 3 }
            ],
            stateOpt: [
                { label: '60-69', value: '60-69' },
                { label: '70-79', value: '70-79' },
                { label: '80-89', value: '80-89' },
                { label: '90-99', value: '90-99' },
                { label: '100以上', value: '100' }
            ],
            rowItem: {}
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        parseTime,
        toView(row) {
            this.rowItem = row
            this.viewVisible = true
        },
        /*新增*/
        newdd() {
            this.rowItem = {
                levelName: '',
                age: [],
                type: '0',
                subsidyStandard: 0
            }
            this.dialogVisible = true
        },
        /*编辑*/
        toEdit(row) {
            this.dialogVisible = true
            this.rowItem = row
        },
        /*删除*/
        toDel(row) {
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    deleteSubsidyLevel({ id: row.id }).then(res => {
                        if (res.state === 0) {
                            this.$message({
                                type: 'success',
                                message: res.data
                            })
                            this.getList()
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        // 管理人员列表
        async getList() {
            this.loading = false
            let params = JSON.parse(JSON.stringify(this.queryParams))
            const res = await getSubsidyLevelList(params)
            this.tableData = res.data.rows
            this.total = res.data.total
        },
        searchList() {
            this.queryParams.pageNum = 1
            this.getList()
        },
        // 翻页
        handleCurrentChange(val) {
            this.loading = true
            this.queryParams.pageNum = val
            this.getList()
        },
        handleSizeChange(val) {
            this.loading = true
            this.queryParams.pageSize = val
        },
        handlerResetList() {
            this.queryParams = {
                pageNum: 1,
                pageSize: 10,
                age: '',
                type: ''
            }
            this.getList()
        },
        //分页序号
        count(index) {
            return (this.queryParams.pageNum - 1) * this.queryParams.pageSize + index + 1
        }
    }
}
</script>

<style lang="scss" scoped>
.wrap {
    padding: 20px;
    background: #fff;
}
.manageBox {
    // margin: 20px;
    background: #fff;
    min-height: calc(100vh - 130px);
    .top {
        .search {
            margin-left: 10px;
        }
        .add {
            float: right;
        }
    }
    .eyes {
        width: 20px;
        height: 20px;
        float: right;
        margin-right: 20px;
        cursor: pointer;
    }
    .operation {
        margin-right: 20px;
        color: #3fcf89;
        cursor: pointer;
    }
    .stop {
        color: #f57473;
    }
    .widthStyle {
        width: 90%;
    }

    /*      /deep/ .el-form-item__content {
              margin-left: 55px !important;
              margin-right: 55px;
          }*/
}
.content {
    /*padding: 15px;*/
    padding-top: 20px;
}
.my_btn_duty {
    margin-right: 20px;
}
.el-button--text {
    outline: none;
    border: none;
}
.el-button--text:hover,
.el-button--text:focus {
    background: none !important;
    border: none !important;
    outline: none !important;
}
.el-button.is-disabled,
.el-button.is-disabled:hover,
.el-button.is-disabled:focus {
    color: #c0c4cc !important;
}
.w200px {
    width: 200px;
}
</style>
